<template>
  <v-main class="wee-main-bg">
    <core-breadcrumb/>
    <router-view />
    <core-footer />
  </v-main>
</template>

<script>
import { defineComponent, } from "@vue/composition-api";
export default defineComponent({
  name: "DashboardCoreView",
  components: {
    CoreFooter: () => import("./Footer"),
    CoreBreadcrumb: () => import("./Breadcrumb"),
  },
  // computed: {
  //   appBgColor() {
  //     return !this.$vuetify.theme.dark ? "appContentBg" : "";
  //   }
  // }
});
</script>
<style lang="scss">
.appContentBg {
  background-color: #fafafa !important;
}
</style>
